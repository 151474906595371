import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getMessaging, onMessage } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB2QsIRxpA41LRK7DULBQMftwdwo33EcBA",
  authDomain: "huvr-313d6.firebaseapp.com",
  databaseURL: "https://huvr-313d6-default-rtdb.firebaseio.com",
  projectId: "huvr-313d6",
  storageBucket: "huvr-313d6.appspot.com",
  messagingSenderId: "282159269063",
  appId: "1:282159269063:web:4bbcdd8454fae3f199eae2",
  measurementId: "G-87XNPJRDNN"
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const messaging = getMessaging(app);
const firestore = getFirestore(app);

export { database, messaging, firestore };